import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Timer from "./Timer";

function App() {
  return (<main>
    <h1>Retour du fiston dans</h1>
    <Timer targetDate="2024-11-11T06:35:00" />
  </main>)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
