import React, { useState, useEffect, useCallback } from 'react';

const Timer = ({ targetDate }) => {
  const calculateTimeLeft = useCallback(() => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};
    
    if (difference > 0) {
      timeLeft = {
        months: Math.floor(difference / (1000 * 60 * 60 * 24 * 30)),
        weeks: Math.floor(difference / (1000 * 60 * 60 * 24 * 7)),
        days: Math.floor((difference / (1000 * 60 * 60 * 24)) % 30),
        daysWeeks: Math.floor((difference / (1000 * 60 * 60 * 24)) % 7),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  });

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Nettoyage de l'intervalle lors du démontage du composant
  }, [calculateTimeLeft, targetDate]);

  return (
    <div className="time">
      {timeLeft.days !== undefined ? (
        <div>
          <p>
            {(timeLeft.months > 0) && <span>{timeLeft.months}mois </span>}
            {(timeLeft.days > 0) && <span>{timeLeft.days}jours </span>}
          </p>
          <p>Soit</p>
          <p>
            {(timeLeft.weeks > 0) && <span>{timeLeft.weeks}semaines </span>}
            {(timeLeft.daysWeeks > 0) && <span>{timeLeft.daysWeeks}jours </span>}
          </p>
          <p>Et</p>
          <p>
            {(timeLeft.hours > 0) && <span>{timeLeft.hours}h </span>}
            {(timeLeft.minutes > 0) && <span>{timeLeft.minutes}m </span>}
            {(timeLeft.seconds > 0) && <span>{timeLeft.seconds}s </span>}
          </p>
        </div>
      ) : (
        <span>Retour passé</span>
      )}
    </div>
  );
};

export default Timer;